<template>
  <div :class="['sushi-types-container', { visible: menuVisible }]">
    <div class="sushi-types">
      <a href="#section-losos" @click.prevent="scrollToSection('section-losos')">
        <img src="../assets/icons/Salmon.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.salmon') }}</span>
      </a>
      <a href="#section-krab" @click.prevent="scrollToSection('section-krab')">
        <img src="../assets/icons/crab.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.crab') }}</span>
      </a>
      <a href="#section-tunets" @click.prevent="scrollToSection('section-tunets')">
        <img src="../assets/icons/tuna.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.tuna') }}</span>
      </a>
      <a href="#section-vugor" @click.prevent="scrollToSection('section-vugor')">
        <img src="../assets/icons/acne.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.eel') }}</span>
      </a>
      <a href="#section-krevetka" @click.prevent="scrollToSection('section-krevetka')">
        <img src="../assets/icons/shrimp.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.shrimp') }}</span>
      </a>
      <a href="#section-gunkani" @click.prevent="scrollToSection('section-gunkani')">
        <img src="../assets/icons/set.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.sets') }}</span>
      </a>
      <a href="#section-dishes" @click.prevent="scrollToSection('section-dishes')">
        <img src="../assets/icons/dishes.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.dishes') }}</span>
      </a>
      <a href="#section-drinks" @click.prevent="scrollToSection('section-drinks')">
        <img src="../assets/icons/drink.svg" alt="Sushi Icon">
        <span>{{ $t('sushi-main.drinks') }}</span>
      </a>
    </div>
  </div>

  <section class="sushi-section f-margin" id="section-news" v-if="filteredSushiList('15').length">
    <h2>{{ $t('sushi-main.new') }}</h2>
    <div class="sushi-grid">
      <div ref="sushiItem" class="sushi-item" v-for="item in filteredSushiList('15')" :key="item.article">
        <div class="sushi-img-container">
          <img :src="item.photo" :alt="item.name" ref="sushiImg" class="sushi-img" @click="navigateToSushiItem(item.article)">
          <img v-if="isFavorite(item.article)" class="favorite-icon" src="../assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
          <img v-else class="favorite-icon" src="../assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
        </div>
        <!-- <div @click="navigateToSushiItem(item.article)"> -->
          <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
          <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
          <div class="button-container" @click="navigateToSushiItem(item.article)">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src="../assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        <!-- </div> -->
      </div>
    </div>
  </section>

  <section class="sushi-section" id="section-losos">
    <h2>{{ $t('sushi-main.sushi-salmon') }}</h2>
    <div class="sushi-grid">
      <div class="sushi-item" v-for="item in filteredSushiList('1')" :key="item.article">
        <div class="sushi-img-container">
          <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
          <img v-if="isFavorite(item.article)" class="favorite-icon" src="../assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
          <img v-else class="favorite-icon" src="../assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
        </div>
          <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
          <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
          <div class="button-container" @click="navigateToSushiItem(item.article)">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src="../assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
      </div>
    </div>
  </section>

  <section class="sushi-section" id="section-krab">
    <h2>{{ $t('sushi-main.sushi-crab') }}</h2>
    <div class="sushi-grid">
      <div class="sushi-item" v-for="item in filteredSushiList('2')" :key="item.article">
        <div class="sushi-img-container">
          <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
          <img v-if="isFavorite(item.article)" class="favorite-icon" src="../assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
          <img v-else class="favorite-icon" src="../assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
        </div>
          <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
          <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
          <div class="button-container" @click="navigateToSushiItem(item.article)">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src="../assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
      </div>
    </div>
  </section>

  <section class="sushi-section" id="section-tunets">
    <h2>{{ $t('sushi-main.sushi-tuna') }}</h2>
    <div class="sushi-grid">
      <div class="sushi-item" v-for="item in filteredSushiList('3')" :key="item.article">
        <div class="sushi-img-container">
          <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
          <img v-if="isFavorite(item.article)" class="favorite-icon" src="../assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
          <img v-else class="favorite-icon" src="../assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
        </div>
          <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
          <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
          <div class="button-container" @click="navigateToSushiItem(item.article)">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src="../assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
      </div>
    </div>
  </section>

  <section class="sushi-section" id="section-vugor" v-if="filteredSushiList('4').length">
    <h2>{{ $t('sushi-main.rolls-eel') }}</h2>
    <div class="sushi-grid">
      <div class="sushi-item" v-for="item in filteredSushiList('4')" :key="item.article">
        <div class="sushi-img-container">
          <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
          <img v-if="isFavorite(item.article)" class="favorite-icon" src="../assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
          <img v-else class="favorite-icon" src="../assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
        </div>
          <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
          <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
          <div class="button-container" @click="navigateToSushiItem(item.article)">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src="../assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
      </div>
    </div>
  </section>

  <section class="sushi-section" id="section-krevetka" v-if="filteredSushiList('5').length">
    <h2>{{ $t('sushi-main.rolls-shrimp') }}</h2>
    <div class="sushi-grid">
      <div class="sushi-item" v-for="item in filteredSushiList('5')" :key="item.article">
        <div class="sushi-img-container">
          <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
          <img v-if="isFavorite(item.article)" class="favorite-icon" src="../assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
          <img v-else class="favorite-icon" src="../assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
        </div>
          <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
          <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
          <div class="button-container" @click="navigateToSushiItem(item.article)">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src="../assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
      </div>
    </div>
  </section>


  <section class="sushi-section" id="section-sweet" v-if="filteredSushiList('7').length">
    <h2>{{ $t('sushi-main.sweet') }}</h2>
    <div class="sushi-grid">
      <div ref="sushiItem" class="sushi-item" v-for="item in filteredSushiList('7')" :key="item.article">
        <div class="sushi-img-container">
          <img :src="item.photo" :alt="item.name" ref="sushiImg" class="sushi-img" @click="navigateToSushiItem(item.article)">
          <img v-if="isFavorite(item.article)" class="favorite-icon" src="../assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
          <img v-else class="favorite-icon" src="../assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
        </div>
        <!-- <div @click="navigateToSushiItem(item.article)"> -->
          <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
          <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
          <div class="button-container" @click="navigateToSushiItem(item.article)">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src="../assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        <!-- </div> -->
      </div>
    </div>
  </section>


    <section class="sushi-section" id="section-gunkani" v-if="filteredSushiList(['6','13']).length">
      <h2>{{ $t('sushi-main.sets') }}</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList(['6','13'])" :key="item.article">
          <div class="sushi-img-container">
            <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
            <img v-if="isFavorite(item.article)" class="favorite-icon" src=".././assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
            <img v-else class="favorite-icon" src=".././assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
          </div>
            <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
            <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
            <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
            <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
            <div class="button-container" @click="navigateToSushiItem(item.article)">
              <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
              <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
            </div>
        </div>
      </div>
    </section>

    <section class="sushi-section" id="section-dishes" v-if="filteredSushiList('8').length">
      <h2>{{ $t('sushi-main.dishes') }}</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('8')" :key="item.article">
          <div class="sushi-img-container">
            <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
            <img v-if="isFavorite(item.article)" class="favorite-icon" src=".././assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
            <img v-else class="favorite-icon" src=".././assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
          </div>
            <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
            <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
            <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
            <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
            <div class="button-container" @click="navigateToSushiItem(item.article)">
              <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
              <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
            </div>
        </div>
      </div>
    </section>

    <section class="sushi-section" id="section-drinks" v-if="filteredSushiList('12').length">
      <h2>{{ $t('sushi-main.drinks') }}</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('12')" :key="item.article">
          <div class="sushi-img-container">
            <img :src="item.photo" :alt="item.name" class="sushi-img" @click="navigateToSushiItem(item.article)">
            <img v-if="isFavorite(item.article)" class="favorite-icon" src=".././assets/icons/favorite-selected.svg" alt="Remove from Favorite" @click="removeFromFavorite(item.article)">
            <img v-else class="favorite-icon" src=".././assets/icons/favorite-not-selected.svg" alt="Add to Favorite" @click="addToFavorite(item.article)">
          </div>
            <h3 class="m-b" @click="navigateToSushiItem(item.article)">{{ item.name }}</h3>
            <span class="article-number" @click="navigateToSushiItem(item.article)">{{ item.article }}</span>
            <p class="g-weight" v-if="item.weight" @click="navigateToSushiItem(item.article)">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
            <p class="m-t" @click="navigateToSushiItem(item.article)">{{ item.description }}</p>
            <div class="button-container" @click="navigateToSushiItem(item.article)">
              <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
              <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
            </div>
        </div>
      </div>
    </section>

    <!-- <section class="sushi-section" id="section-package">
      <h2>Пакет</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('14')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight">{{ item.weight }} {{ $t('sushi.weightUnit') }}</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} {{ $t('sushi.currency') }}</span>
            <button @click.stop="addToCart(item)">{{ $t('buttons.want') }}<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>

      </div>
    </section> -->


    <section class="app-section" id="application-section">
      <h2>{{ $t('appSection.header') }}</h2>
      <div class="app-download">
        <div class="app-info">
          <h3>{{ $t('appSection.header2') }}</h3>
          <p>{{ $t('appSection.description') }}</p>
          <button v-if="deferredPrompt" @click="installPWA()">{{ $t('appSection.downloadButton') }}<img src=".././assets/download_icon.png" alt="Plus Icon"></button>
          <button v-else @click="showInstallPWAPopup()">{{ $t('appSection.downloadButton') }}<img src=".././assets/download_icon.png" alt="Plus Icon"></button>
        </div>
        <!-- <button v-if="deferredPrompt" @onClick="promptInstall">
          Add to home screen
        </button> -->
        <img src=".././assets/iso_download.png" alt="App Image" class="app-image">
      </div>

      <!-- Custom popup for non-Android/iOS users to install the PWA -->
      <div v-if="showPopup" class="custom-install-popup">
        <div class="popup-content">
          <h3>{{ $t('appSection.popupTitle') }}</h3>
          <p v-if="isIOS" v-html="$t('appSection.popupIOS')"></p>
          <p v-else>{{ $t('appSection.popupBrowser') }}</p>
         <p>
            {{ $t('appSection.instruction') }}
            <a href="#/installation-instructions" class="instruction-link">/instruction</a>
          </p>
          <button @click="closePopup">{{ $t('appSection.close') }}</button>
        </div>
      </div>
    </section>



    <PromoCarousel />

    <section class="section-map">
      <div class="section-map-header">
        <h2>{{ $t('sushi-main.delivery-address') }}</h2>
        
      </div>
      <!-- <div class="map-container" v-if="activeRestaurant">
        <iframe :src="activeRestaurant.gmapsLink" allowfullscreen></iframe>
      </div> -->
      <div class="map-container">
        <iframe src="https://www.google.com/maps/d/embed?mid=1LS-hIzNEWIPFUY_uyiD_AQp52KXJc6k&ehbc=2E312F" allowfullscreen></iframe>
      </div>


      <!-- <div class="contact-info">
        <div class="contact-item">
          <h3>Адреса</h3>
          <p>м. Київ, вул. Прикладна, 123</p>
        </div>
        <div class="contact-item">
          <h3>Години роботи</h3>
          <p>Пн-Нд: 10:00 - 22:00</p>
        </div>
        <div class="contact-item">
          <h3>Контактний номер</h3>
          <p>+38 (050) 123-45-67</p>
        </div>
      </div> -->
      <PWAPrompt :promptOnVisit="1" :timesToShow="3" :copyClosePrompt="Close" :permanentlyHideOnDismiss="false"/>
    </section>
    <!-- Popup if the user is not logged in -->
    <div v-if="showLoginPopup" class="login-popup">
      <h2>{{ $t('loginpopup.popupTitle') }}</h2>
      <button @click="redirectToLogin">{{ $t('buttons.login') }}</button>
    </div>
</template>

<script>
import PromoCarousel from './PromoCarousel.vue';
import axios from 'axios';

export default {
  name: 'HelloWorld',
  components: {
    PromoCarousel,
    
  },
  data() {
    return {
      lastScrollTop: 0,
      menuVisible: true,
      showPopup: false,
      isIOS: false,
      deferredPrompt: null,
      showLoginPopup: false, // Popup visibility state
      favoriteSushi: '',
      activeRestaurant: null,

      sushiList: [
       {
        article: '5009',
        name: 'Рол Lovely',
        weight: '320',
        description: 'Рис, крем-сир, краб, соус шрірача, лосось, кумкват',
        price: '329',
        photo: '/img/sushi/5009_front_mini.jpeg',
        category: '2'
      },
      {
        article: '5002',
        name: 'Рол чіз з крабом',
        weight: '350',
        description: 'Рис, чорнило каракатиці, чеддeр, крем-сир, краб, манго, унагі, ікра тобіко',
        price: '289',
        photo: '/img/sushi/5002_front_mini.jpeg',
        category: '2'
      },
      {
        article: '5005',
        name: 'Каліфорнія з лососем',
        weight: '330',
        description: 'Рис, огірок, авокадо, лосось, ікра тобіко, манго, соус спайсі , солодкий чилі',
        price: '339',
        photo: '/img/sushi/5005_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5016',
        name: 'Філадельфія з лососем',
        weight: '320',
        description: 'Рис, крем-сир, огірок, авокадо, лосось',
        price: '269',
        photo: '/img/sushi/5016_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5011',
        name: 'Макі з лососем і вугрем',
        weight: '250',
        description: 'Рис, норі, лосось, вугор, крем-сир, огірок, манго, авокадо, соус унагі, соус спайсі',
        price: '259',
        photo: '/img/sushi/5011_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5008',
        name: 'Червоний дракон',
        weight: '360',
        description: 'Рис, норі, лосось, огірок, авокадо, ікра тобіко, томаго, зелена цибуля, креветка',
        price: '365',
        photo: '/img/sushi/5008_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5003',
        name: 'Спрінг рол з лососем',
        weight: '230',
        description: 'Рисовий папір, лосось, крем-сир, огірок, пекінська капуста, ікра тобіко, світ чилі, кумкват',
        price: '289',
        photo: '/img/sushi/5003_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5014',
        name: 'Філадельфія з тунцем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, тунець',
        price: '279',
        photo: '/img/sushi/5014_front_mini.jpeg',
        category: '3'
      },
      {
        article: '5010',
        name: 'Рол з тунцем',
        weight: '355',
        description: 'Рис, крем-сир, огірок, тунець, манго, світ чилі, унагі, тагараші, кумкват',
        price: '289',
        photo: '/img/sushi/5010_front_mini.jpeg',
        category: '3'
      },
      {
        article: '5007',
        name: 'Золотий дракон',
        weight: '355',
        description: 'Рис, норі, вугор, огірок, авокадо, зелена цибуля, ікра тобіко, соус спайсі, лосось, унагі, томаго',
        price: '439',
        photo: '/img/sushi/5007_front_mini.jpeg',
        category: '4'
      },
      {
        article: '5006',
        name: 'Тигровий дракон',
        weight: '375',
        description: 'Рис, норі, креветка, огірок, авокадо, зелена цибуля, соус спайсі, вугор, томаго, ікра тобіко',
        price: '415',
        photo: '/img/sushi/5006_front_mini.jpeg',
        category: '4'
      },
      {
        article: '5013',
        name: 'Філадельфія з вугрем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, вугор',
        price: '309',
        photo: '/img/sushi/5013_front_mini.jpeg',
        category: '4'
      },
      {
        article: '5012',
        name: 'Смажений з креветкою',
        weight: '260',
        description: 'Рис, норі, крем-сир, креветка, моцарела, ікра тобіко, кляр панко, соус спайсі',
        price: '250',
        photo: '/img/sushi/5012_front_mini.jpeg',
        category: '5'
      },
      {
        article: '5015',
        name: 'Філадельфія з креветкою',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, креветка',
        price: '335',
        photo: '/img/sushi/5015_front_mini.jpeg',
        category: '5'
      },
      {
        article: '5004',
        name: 'Сет гунканів',
        weight: '570',
        description: 'Рис, норі, лосось, тунець, вугор, краб, ікра тобіко, соус спайсі, соус світ чилі',
        price: '730',
        photo: '/img/sushi/5004_front_mini.jpeg',
        category: '6'
      },
      {
        article: '5001',
        name: 'Рол Снікерс',
        weight: '255',
        description: 'Крем-сир, Нутелла, банан, топінг полуниця, полуниця',
        price: '279',
        photo: '/img/sushi/5001_front_mini.jpeg',
        category: '7'
      },
        {
          article: '5040',
          name: 'Напій Caycen SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5040_front_mini.png',
          category: '12'
        },
        {
          article: '5041',
          name: 'Напій Тамаринд SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5041_front_mini.png',
          category: '12'
        },
        {
          article: '5042',
          name: 'Напій Маракуйя SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5042_front_mini.png',
          category: '12'
        },
        {
          article: '5043',
          name: 'Напій Рожева Гуава SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5043_front_mini.png',
          category: '12'
        },
        {
          article: '5044',
          name: 'Напій Манго SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5044_front_mini.png',
          category: '12'
        },
        {
          article: '5045',
          name: 'Пепсі 0.33 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5045_front_mini.png',
          category: '12'
        },
        {
          article: '5046',
          name: 'Пепсі чорна 0.25 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5046_front_mini.png',
          category: '12'
        },
        {
          article: '5047',
          name: '7up 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5047_front_mini.png',
          category: '12'
        },
        {
          article: '5048',
          name: 'Mirinda 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5048_front_mini.png',
          category: '12'
        },
        {
          article: '5049',
          name: 'Карпатська Джерельна газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5049_front_mini.png',
          category: '12'
        },
        {
          article: '5050',
          name: 'Карпатська Джерельна н/газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5050_front_mini.png',
          category: '12'
        },
        {
          article: '5051',
          name: 'Сік Сандора апельсин 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5051_front_mini.png',
          category: '12'
        },
        {
          article: '5052',
          name: 'Сік Сандора мультивітамін 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5052_front_mini.png',
          category: '12'
        },
        {
          article: '5053',
          name: 'Сік Сандора томат 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5053_front_mini.png',
          category: '12'
        },
        {
          article: '5054',
          name: 'Сік Сандора яблучний 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5054_front_mini.png',
          category: '12'
        },
        {
          article: '5061',
          name: 'Креветка попкорн',
          weight: '162',
          description: 'Креветка, борошно, олія соняшникова, розпушувач, соус солодкий чилі, соус унагі',
          price: '170',
          photo: '/img/sushi/5061_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5062',
          name: 'Фіш боул',
          weight: '300',
          description: 'Лосось, цибуля синя, яйце куряче, панко, олія соняшникова, борошно, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5062_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5063',
          name: 'Тяхан з лососем',
          weight: '350',
          description: 'Рис, болгарський перець, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, лосось',
          price: '280',
          photo: '/img/sushi/5063_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5064',
          name: 'Фунчоза з креветкою',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, креветка',
          price: '330',
          photo: '/img/sushi/5064_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5065',
          name: 'Локшина соба з лососем',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, лосось',
          price: '280',
          photo: '/img/sushi/5065_front_mini.jpeg',
          category: '8'
        },

        {
          article: '5066',
          name: 'Футомакі з лососем',
          weight: '240',
          description: 'Рис, норі, крем-сир, огірок, лосось, соус спайсі',
          price: '215',
          photo: '/img/sushi/5066_front_mini.jpeg',
          category: '1'
        },

        {
          article: '5067',
          name: 'Тяхан з креветкою',
          weight: '350',
          description: 'Рис, перець болгарський, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, креветка',
          price: '270',
          photo: '/img/sushi/5067_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5068',
          name: 'Фунчоза з лососем',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, лосось',
          price: '330',
          photo: '/img/sushi/5068_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5069',
          name: 'Локшина соба з креветкою',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, креветка',
          price: '280',
          photo: '/img/sushi/5069_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5060',
          name: 'Вогняний дракон',
          weight: '370',
          description: 'Рис, норі, манго, огірок, крем-сир, лосось, унагі, вугор, тобіко, перець чилі',
          price: '420',
          photo: '/img/sushi/5060_front_mini.jpeg',
          category: '1'
        },
        {
          article: '5070',
          name: 'Сет Токаш',
          weight: '1250',
          description: 'Рол з тунцем, спрінг рол з лососем, макі рол з огірком, каліфорнія з вугрем, тобіко, нігірі, футомакі з лососем, імбир, васабі',
          price: '1159',
          photo: '/img/sushi/5070_front_mini.jpeg',
          category: '13'
        },
        {
          article: '5071',
          name: 'Сет Філадельфія мікс',
          weight: '720',
          description: 'Рол філадельфія мікс: лосось та тунець + Рол філадельфія мікс: креветка та вугор + Рол філадельфія з авокадо',
          price: '780',
          photo: '/img/sushi/5071_front_mini.jpeg',
          category: '13'
        },
        {
          article: '5072',
          name: 'Рол Токаш',
          weight: '480',
          description: 'Рис, Норі, Крем-сир, Креветка, Яйце куряче, Панко, Огірок, Краб, Лосось, Ікра Тобіко, Кумкват, Устричний соус',
          price: '420',
          photo: '/img/sushi/5072_front_mini.jpeg',
          category: '1'
        },
        {
          article: '5073',
          name: 'РолДог',
          weight: '460',
          description: 'Рис, норі, крем-сир, тостовий сир, огірок, креветка, крабова паличка, соус спайсі, соус унагі, панко, яйце куряче, борошно, олія соняшникова',
          price: '315',
          photo: '/img/sushi/5073_front_mini.jpeg',
          category: '5'
        },
        {
          article: '5074',
          name: 'Рол від Шефа',
          weight: '430',
          description: 'Рис, норі, креветка, крем-сир, томаго, вугор, соус унагі, майонез, трюфельна сальса, стружка кокоса, огірок',
          price: '420',
          photo: '/img/sushi/5074_front_mini.jpeg',
          category: '4'
        },
        {
          article: '5075',
          name: 'Рол Баунті',
          weight: '290',
          description: 'Тісто листове, крем-сир, кокосова стружка, пудра сахарна, банан, карамельний топінг',
          price: '239',
          photo: '/img/sushi/5075_front_mini.jpeg',
          category: '7'
        },
        {
          article: '5078',
          name: 'Лимонад "Зандукелі" Груша 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5078_front_mini.jpeg',
          category: '12'
        },
        {
          article: '5079',
          name: 'Лимонад "Зандукелі" Сапераві 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5079_front_mini.jpeg',
          category: '12'
        },
        {
          article: '5080',
          name: 'Лимонад "Зандукелі" Тархун 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5080_front_mini.jpeg',
          category: '12'
        },
        {
          article: '5081',
          name: 'Смажений с лососем',
          weight: '260',
          description: 'Рис, сир Чедар, лосось, крем сир, норі, соус Спайсі',
          price: '250',
          photo: '/img/sushi/5081_front_mini.jpeg',
          category: '1'
        },
        {
          article: '5082',
          name: 'Рол без рису',
          weight: '220',
          description: 'Норі, лосось, тунець, креветка, авокадо, огірок',
          price: '489',
          photo: '/img/sushi/5082_front_mini.jpeg',
          category: '3'
        },
        {
          article: '5083',
          name: 'Сет Класік',
          weight: '1400',
          description: 'Філадельфія з лососем, Футомакі з креветкою, Каліфорнія з вугрем, Ро чеддер, Макі з тунцем, Макі з авокадо і манго, Нігірі з лососем',
          price: '850',
          photo: '/img/sushi/5083_front_mini.jpeg',
          category: '13'
        },
        {
          article: '5084',
          name: 'Темакі',
          weight: '250',
          description: 'Рис, норі, лосось, крем-сир, огірок, тобіко, унагі',
          price: '240',
          photo: '/img/sushi/5084_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5085',
          name: 'Суші Бургер',
          weight: '310',
          description: 'Рис, норі, сир чеддер, крем-сир, тобіко, лосось, креветки, краб, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5085_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5083',
          name: 'Сет Класік',
          weight: '1400',
          description: 'Філадельфія з лососем, Футомакі з креветкою, Каліфорнія з вугрем, Ро чеддер, Макі з тунцем, Макі з авокадо і манго, Нігірі з лососем',
          price: '850',
          photo: '/img/sushi/5083_front_mini.jpeg',
          category: '15'
        },
        {
          article: '5084',
          name: 'Темакі',
          weight: '250',
          description: 'Рис, норі, лосось, крем-сир, огірок, тобіко, унагі',
          price: '240',
          photo: '/img/sushi/5084_front_mini.jpeg',
          category: '15'
        },
        {
          article: '5085',
          name: 'Суші Бургер',
          weight: '310',
          description: 'Рис, норі, сир чеддер, крем-сир, тобіко, лосось, креветки, краб, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5085_front_mini.jpeg',
          category: '15'
        },
        {
          article: '5555',
          name: 'Пакет',
          weight: '2',
          description: 'Пакет',
          price: '10',
          photo: '/img/sushi/5555_front.jpeg',
          category: '14'
        }
      ],
    };
  },
  props: {
    msg: String
  },
  watch: {
    '$i18n.locale': 'fetchAndParseSushiData', // Watch for language changes
  },
  created() {
    this.fetchAndParseSushiData();
    this.loadFavoriteSushiFromStorage();
    this.loadActiveRestaurantFromStorage();
  },
  mounted() {
    // Check if the platform is iOS
    this.isIOS = this.checkIfIOS();

    window.addEventListener('scroll', this.handleScroll);
     // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      console.log(`'beforeinstallprompt' event was fired.`);
      e.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = e;
      // Show an install promotion button or update UI
      //this.showInstallPromotion();
    });

    // const hash = window.location.hash;
    // if (hash) {
    //   this.scrollToSection(hash);
    // }
    this.scrollOnPageLoad();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    loadActiveRestaurantFromStorage() {
      const storedActiveRestaurant = localStorage.getItem('activeRestaurant');
      if (storedActiveRestaurant) {
        this.activeRestaurant = JSON.parse(storedActiveRestaurant);
      }
    },
    async fetchUserInfo() {
      try {
        const token = this.getTokenWithExpiry('userToken'); // Ensure token exists

        if (!token) {
          this.redirectToSignIn();
          return;
        }

        const response = await axios.get('https://api.sushitokash.com.ua/api/users/info', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const userInfo = response.data;

        // Save the favoriteSushi string in data
        this.favoriteSushi = userInfo.favoriteSushi || '';
        this.saveFavoriteSushiToStorage(); // Store it in local storage

        console.log('User Info:', userInfo);
      } catch (error) {
        console.error('Error fetching user info:', error);
        this.redirectToSignIn(); // Handle error, like token expiration
      }
    },
    isFavorite(article) {
      const favoriteList = this.favoriteSushi.split(' ');
      return favoriteList.includes(article);
    },
    // Add sushi article to the favorite list
    async addToFavorite(article) {
      try {
        const token = this.getTokenWithExpiry('userToken'); // Ensure token exists
        console.log(token)
         if (!token) {
           console.log("Myu")
          this.showLoginPopup = true; // Show login popup if token is null
          return;
        }
        const favoriteList = this.favoriteSushi.split(' ');

        if (!favoriteList.includes(article)) {
          favoriteList.push(article);
          this.favoriteSushi = favoriteList.join(' ');

          // Make PUT request to update favorite sushi list on the server
          const response = await axios.put('https://api.sushitokash.com.ua/api/users/update-favorite-sushi', {
            favoriteSushiArticles: favoriteList
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          // Update favoriteSushi with the response and save to localStorage
          this.favoriteSushi = response.data.favoriteSushi || '';
          this.saveFavoriteSushiToStorage(); // Save updated favorite sushi in local storage
        }
      } catch (error) {
        console.error('Error adding sushi to favorites:', error);
      }
    },

    // Remove sushi article from the favorite list
    async removeFromFavorite(article) {
      try {
        const token = this.getTokenWithExpiry('userToken'); // Ensure token exists
        console.log("REMOVE from f" )
        console.log(token);
        if (!token) {
          this.showLoginPopup = true; // Show login popup if token is null
          return;
        }
        let favoriteList = this.favoriteSushi.split(' ');

        if (favoriteList.includes(article)) {
          favoriteList = favoriteList.filter(fav => fav !== article);
          this.favoriteSushi = favoriteList.join(' ');

          const favoriteListToremove = article;


          // Make request to remove sushi article from favorite list on the server
          const response = await axios.put('https://api.sushitokash.com.ua/api/users/remove-favorite-sushi', {
            sushiArticlesToRemove: [favoriteListToremove]
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          this.favoriteSushi = response.data.favoriteSushi || '';
          // Save the updated favorite list in localStorage
          this.saveFavoriteSushiToStorage();
        }
      } catch (error) {
        console.error('Error removing sushi from favorites:', error);
      }
    },
    saveFavoriteSushiToStorage() {
      localStorage.setItem('favoriteSushi', this.favoriteSushi); // Save as a space-separated string
    },
    getTokenWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null; // Token does not exist
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key); // Token expired
        return null;
      }
      return item.value; // Return valid token
    },
    redirectToLogin() {
      this.$router.push("/login"); // Assuming you have a login route
    },
    loadFavoriteSushiFromStorage() {
      const storedFavorites = localStorage.getItem('favoriteSushi');
      if (storedFavorites) {
        this.favoriteSushi = storedFavorites; // Load as string
      }
    },
    addToCartWithAnimation(item, event) {
      const sushiItemElement = event.currentTarget.closest('.sushi-item');
      const sushiImg = sushiItemElement ? sushiItemElement.querySelector('.sushi-img') : null;
      
      if (!sushiImg) {
        console.error('Sushi image element not found!');
        return;
      }

      // Clone the sushi image for animation
      const sushiImgClone = sushiImg.cloneNode(true);
      document.body.appendChild(sushiImgClone);

      // Get the position of the clicked sushi item
      const sushiItemPosition = sushiImg.getBoundingClientRect();

      // Hardcode the position of the cart icon (e.g., top-right corner)
      const cartIconX = window.innerWidth - 50; // Adjust based on actual fixed position
      const cartIconY = 20; // Adjust based on actual fixed position
      
      // Calculate the X and Y distance to the cart icon
      const translateX = cartIconX - sushiItemPosition.left;
      const translateY = cartIconY - sushiItemPosition.top;

      // Set initial position of the cloned image
      sushiImgClone.style.position = 'absolute';
      sushiImgClone.style.left = `${sushiItemPosition.left}px`;
      sushiImgClone.style.top = `${sushiItemPosition.top}px`;
      sushiImgClone.style.width = `${sushiItemPosition.width}px`;
      sushiImgClone.style.height = `${sushiItemPosition.height}px`;
      sushiImgClone.style.transition = 'transform 1s ease-in-out';
      
      // Trigger the flying animation towards the cart
      setTimeout(() => {
        sushiImgClone.style.transform = `translate(${translateX}px, ${translateY}px) scale(0.2)`;
      }, 10); // Small delay to allow positioning
      
      // Remove the clone after the animation ends
      sushiImgClone.addEventListener('transitionend', () => {
        sushiImgClone.remove();
        // Optionally, you can add the item to the cart after the animation
        this.addToCart(item);
      });
    },


    checkIfIOS() {
      const userAgent = window.navigator.userAgent;
      return /iPhone|iPad|iPod/.test(userAgent);
    },
    showInstallPWAPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    promptInstall() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null;
        });
      }
    },
    parseSushiData(sushiData) {
      const isEnglish = this.$i18n.locale === 'en'; // Check the current language

      // Check if activeRestaurant exists in localStorage
      const activeRestaurant = JSON.parse(localStorage.getItem('activeRestaurant'));

      if (!activeRestaurant) {
        console.warn('No active restaurant found.');
        return sushiData.map(item => {
          let article = item.id ? item.id.toString() : '';

          // If the article starts with '7', remove the first character
          if (article.startsWith('7')) {
            article = article.substring(1);
          }

          return {
            article: article,
            name: isEnglish ? item.nameEn || '' : item.nameUa || '',
            description: isEnglish ? item.descriptionEn || '' : item.descriptionUa || '',
            weight: item.weight ? item.weight.toString() : '',
            price: item.price ? item.price.toString() : '',
            photo: item.imgMiddle || '',
            category: item.category || '',
          };
        });
      }

      return sushiData
        .filter(item => item.status === 'ACTIVE' && item.restaurantId === activeRestaurant.id) // Filter only ACTIVE items with matching restaurantId
        .map(item => {
          let article = item.id ? item.id.toString() : '';

          // If the article starts with '7', remove the first character
          if (article.startsWith('7')) {
            article = article.substring(1);
          }

          return {
            article: article,
            name: isEnglish ? item.nameEn || '' : item.nameUa || '',
            description: isEnglish ? item.descriptionEn || '' : item.descriptionUa || '',
            weight: item.weight ? item.weight.toString() : '',
            price: item.price ? item.price.toString() : '',
            photo: item.imgMiddle || '',
            category: item.category || '',
          };
        });
    },


    fetchAndParseSushiData() {
      try {
      axios.get('https://api.sushitokash.com.ua/api/sushi-items')
        .then(response => {
          this.sushiList = this.parseSushiData(response.data);
          console.log(this.sushiList);
        })
        .catch(error => {
          console.error("There was an error fetching the sushi items:", error);
        });
      } catch (error) {
                    console.error('Cannot update items:'); 
      }
    },
    filteredSushiList(categories) {
    // Ensure categories is an array, even if a single category is passed
      if (!Array.isArray(categories)) {
        categories = [categories];
      }
      return this.sushiList.filter(item => categories.includes(item.category));
    },

     addToCart(item) {
      this.$emit('add-to-cart', item);
    },
    // addToCart(article) {
    //   const articleStr = String(article); // Convert article to string
    //   console.log("addToCart called with article:", articleStr);
    //   console.log("Current sushiList:", this.sushiList);

    //   this.sushiList.forEach(sushi => {
    //     console.log("Sushi article:", sushi.article);
    //   });

    //   const item = this.sushiList.find(sushi => String(sushi.article) === articleStr); // Ensure comparison is between strings
    //   console.log("Found item:", item);

    //   if (item) {
    //     this.$emit('add-to-cart', item);
    //   } else {
    //     console.error("Item not found in sushiList for article:", articleStr);
    //   }
    // },
    // showInstallPromotion() {
    //   // Display a custom button or UI element prompting the user to install the PWA
    //   const installButton = document.createElement('button');
    //   installButton.textContent = 'Install Суші Токаш PWA';
    //   installButton.classList.add('install-button');
    //   document.body.appendChild(installButton);

    //   installButton.addEventListener('click', async () => {
    //     // Hide the install button after it's clicked
    //     installButton.style.display = 'none';
    //     // Show the install prompt
    //     this.deferredPrompt.prompt();
    //     // Wait for the user to respond to the prompt
    //     const { outcome } = await this.deferredPrompt.userChoice;
    //     console.log(`User response to the install prompt: ${outcome}`);
    //     // Clear the deferredPrompt after use
    //     this.deferredPrompt = null;
    //   });
    // },
    installPWA() {
      console.log("install PWa")
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
        });
      }
    },

    handleClick() {
      // Initiate a call
      window.location.href = 'tel:+380800338105';
      
      // Navigate to the sushi item
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop < 100) {
        // Less than 100px from the top, show the menu
        this.menuVisible = true;
      } else if (scrollTop > this.lastScrollTop) {
        // Scrolling down, hide the menu
        this.menuVisible = false;
      } else {
        // Scrolling up, show the menu
        this.menuVisible = true;
      }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    },
  
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollOnPageLoad() {
      // Check if the URL has a hash on page load
      const hash = window.location.hash;
      console.log(hash)
      if (hash) {
        let sectionId0 = hash.replace('/#', '');  // Remove the "#" from the hash
        const sectionId = sectionId0.replace('#', '');
        console.log(sectionId);
        const element = document.getElementById(sectionId);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
          }, 100); // Delay of 1.5 seconds
        }
      }
    },
    navigateToSushiItem(article) {
      this.$router.push({ name: 'SushiItem', params: { article } });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Add the custom popup styling */
.custom-install-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color:black;
  z-index: 1000;
}

.custom-install-popup a {
  color: black
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 20px;
}

.popup-content button {
  background-color: #88c057;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #7bb249;
}


.sushi-types-container {
  width: 100%;
  /* background-color: #333; */
  padding: 5px 0px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  position: fixed;
  top: 0;
  z-index: 2;
  transition: transform 0.3s ease;
  transform: translateY(-100%); /* Hide the menu by default */
}

.sushi-types-container.visible {
  transform: translateY(0); /* Show the menu when visible */
}

.sushi-types {
  display: flex;
  justify-content: space-between;
  width: 60%;  /* Adjust this value as needed */
  margin: 0 auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
.sushi-types::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.sushi-types a {
  text-decoration: none;
  color: inherit;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.sushi-types a img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.sushi-types a span {
  margin-top: 5px;
  font-size: 14px;
  color: #E9E9E9;
}

@media (max-width: 700px) {
  .sushi-types {
    width: 86%;
    overflow-x: auto;
    gap: 10px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }
}

@media (max-width: 500px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 15px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 13px;
  }
}

@media (max-width: 380px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 3px;
    gap: 7px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 27px;
    height: 27px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 12px;
  }
}

@media (max-width: 330px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 0px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 27px;
    height: 27px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 12px;
  }
}

.f-margin {
  margin-top: 90px !important;
}

/* Additional styles for the sushi sections and items */
.sushi-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  padding-top: 60px; /* Adjust to avoid overlapping with the fixed header */
}

.sushi-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}

.sushi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.sushi-item {
  cursor: pointer;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sushi-item .article-number {
  display: block;
  margin-left: 10px;
  color: #FF5C5C;
  font-size: 0.9em;
}

.sushi-item img {
  width: 100%;
  height: auto;
}

.sushi-item p {
  padding: 10px;
  color: #E9E9E9;
}

.sushi-item h3 {
  padding: 10px;
  color: #E9E9E9;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.price {
  font-size: 18px;
  color: #FF5C5C;
  font-weight: bold;
}

.sushi-item button {
  background-color: #88c057;  /* Light green background color */
  color: #333;  /* Darker text color for contrast */
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
}

.sushi-item button:hover {
  background-color: #7bb249;  /* Slightly darker green on hover */
}

.sushi-item button img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.sushi-item .weight {
  color: #FF5C5C;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
}


.section-map {
      width: 100%;
      margin: 0 auto;
      background-color: #353535;
      border-radius: 10px;
      overflow: hidden;
      padding-bottom: 40px;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }

    .section-map-header {
      text-align: center;
      padding: 40px 20px;
      background-color: #353535; /* Soft pink background */
    }

    .section-map-header h2 {
      font-size: 32px;
      margin-bottom: 10px;
      color: #E9E9E9; /* Deep red color */
    }

    .section-map-header p {
      font-size: 18px;
      color: #555;
    }

    .map-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */
      height: 0;
      margin-left: 80px;
      margin-right: 80px;
      overflow: hidden;
    }

    .map-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    .contact-info {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 20px;
      background-color: #fff;
    }

    .contact-item {
      flex: 1 1 300px;
      margin: 20px;
      text-align: center;
    }

    .contact-item h3 {
      font-size: 24px;
      margin-bottom: 10px;
      color: #d13f3f;
    }

    .contact-item p {
      font-size: 16px;
      color: #555;
    }

    @media (max-width: 768px) {
      .section-map-header h2 {
        font-size: 24px;
      }

      .section-map-header p {
        font-size: 16px;
      }

      .contact-item h3 {
        font-size: 20px;
      }

      .contact-item p {
        font-size: 14px;
      }
      .map-container {
        position: relative;
        padding-bottom: 70.25%; /* 16:9 aspect ratio */
        height: 0;
        margin-left: 50px;
        margin-right: 50px;
        overflow: hidden;
      }
    }
      @media (max-width: 450px) {
        .map-container {
        position: relative;
        padding-bottom: 100.25%; /* 16:9 aspect ratio */
        height: 0;
        margin-left: 10px;
        margin-right: 10px;
        overflow: hidden;
      }

      }

      .g-weight{
         font-size: 14px; /* Adjust the size */
          color: #FF5C5C !important; /* Lighter color for secondary information */
          /* margin-top: 5px; Spacing from the above element */
          font-weight: bold; /* Bold for emphasis */
          margin: 0px;
        }
      .m-b {
        margin-bottom: 5px;
      }
      .m-t {
        margin-top: 5px;
      }
    
@keyframes fly-to-basket {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(var(--translate-x), var(--translate-y)) scale(0.3);
    opacity: 0;
  }
}

.sushi-img.flying {
  position: fixed;
  z-index: 1000;
  animation: fly-to-basket 0.8s ease-in-out forwards;
}

.sushi-img-container {
    position: relative;
    display: inline-block;
}

.favorite-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px !important;
    height: 30px !important;
    cursor: pointer;
}



.login-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  min-width: 220px;
}

.login-popup h2 {
  margin-bottom: 20px;
}

.login-popup button {
  background-color: #ff5c5c;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
